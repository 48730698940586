import Vue from 'vue'
import { BLANK_ID } from '@/constants/registerData';
import debounce from 'lodash/debounce'
import throttle from 'lodash/throttle';
import { CellEditEndingEventArgs, CellRange } from '@mescius/wijmo.grid'
import { NotifyCollectionChangedEventArgs, NotifyCollectionChangedAction } from '@mescius/wijmo'
import pick from 'lodash/pick'
import store from '@/store';
import { KEYS_CODE } from '@/constants/keyboard'
/**
 *
 * @param {*} target
 * @param {*} row
 * @param {*} row2
 * @param {*} cloneDataItem
 * @returns {row1: rowValues, row2: rowValues}
 */
export const getRowsData = (target, row, row2, cloneDataItem = false) => {
  let rowsData = {};

  for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
    const row = target.rows[rowIndex]
    let dataItem = {}
    if (!!row && !!row.dataItem) {
      dataItem = row.dataItem
    }

    if (cloneDataItem) {
      rowsData[rowIndex] = { ...dataItem }
    } else {
      rowsData[rowIndex] = dataItem
    }
  }

  return rowsData
}


const getRealValue = value => {
  return (!value && value !== 0) ? null : value
}

/**
 *
 * @param {*} target
 * @param {number} row
 * @param {number} row2
 * @param {number} col
 * @param {number} col2
 * @returns {row1: {col1: valueCol1, col2: valueCol2}, row2: {col1: valueCol1, col2: valueCol2}}
 */
export const getRangeData = (target, row, row2, col, col2) => {
  let selectionData = {};

  for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
    if (rowIndex < 0) {
      return
    }
    let rowData = {};

    for (let colIndex = col; colIndex <= col2; colIndex++) {
      if (colIndex < 0) {
        return
      }
      rowData[colIndex] = target.getCellData(rowIndex, colIndex, false);
    }

    selectionData[rowIndex] = rowData;
  }

  return selectionData
}

export class Actionable {
  _oldState = null
  selection = null
  _target = null

  constructor(target, oldState, selection) {
    this._target = target
    this._oldState = oldState
    this.selection = selection
  }

  undo() {
    this.applyState(this._oldState)
  }

  redo() {
    this.applyState(this._newState)
  }
}

export class GridCellEditAction extends Actionable {
  constructor(target, oldState, selection) {
    super(target, oldState, selection)
    const { row, row2, col, col2 } = selection
    this._newState = getRangeData(target, row, row2, col, col2);
  }

  applyState(state) {
    const { row, row2, col, col2 } = this.selection
    const view = this._target.collectionView
    this._target.deferUpdate(() => {
      let columnsMap = this._target.columns.map((column) => column.binding)

      for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
        for (let colIndex = col; colIndex <= col2; colIndex++) {
          this._target.setCellData(rowIndex, colIndex, state[rowIndex][colIndex], false, true)
          const e = new CellEditEndingEventArgs(this._target, new CellRange(rowIndex, colIndex), state[rowIndex][colIndex])
          e.custom = true
          this._target.onCellEditEnded(e)
        }
      }

      setTimeout(() => {
        for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
          for (let colIndex = col; colIndex <= col2; colIndex++) {
            const e = new CellEditEndingEventArgs(this._target, new CellRange(rowIndex, colIndex), state[rowIndex][colIndex])
            e.custom = true
            this._target.onCellEditEnded(e)
          }
        }
      }, 100);

      // case: undo after input cell by cell
      setTimeout(() => {
        const rowsData = getRowsData(this._target, row, row2, true)
        let rowsRemovedIndex = []

        for (const rowIndex in rowsData) {
          let rowData = rowsData[rowIndex]
          let rowDataPick = pick(rowData, columnsMap)
          let allFieldsIsEmpty = Object.keys(rowDataPick).every(key => {
            if (key === 'id' || key === 'emissions') {
              return true
            }

            return rowDataPick[key] === null || rowDataPick[key] === undefined
          })

          if (allFieldsIsEmpty) {
            rowsRemovedIndex.push(rowIndex)
            view.itemsRemoved.push(rowData)
          }
        }

        if (rowsRemovedIndex.length) {
          let event = new NotifyCollectionChangedEventArgs(NotifyCollectionChangedAction.Remove)
          view.onCollectionChanged(event)
          let idColIndex = columnsMap.findIndex(column => column === 'id')
          if (idColIndex >= 0) {
            for (const rowRemovedIndex of rowsRemovedIndex) {
              this._target.setCellData(Number(rowRemovedIndex), Number(idColIndex), BLANK_ID, false, true)
            }
          }
        }
      }, 400)
    })
  }
}

export class GridDeletingRowAction extends Actionable {
  undo() {
    const { row, row2 } = this.selection
    const view = this._target.collectionView
    const source = view.sourceCollection
    let columnsMap = this._target.columns.map((column) => column.binding)

    view.deferUpdate(() => {
      for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
        const rowItem = {
          ...this._oldState[rowIndex],
          type: this._oldState[rowIndex]?.type ? 1 : 0,
          type_1: this._oldState[rowIndex]?.type_1 ? 1 : 0,
        }

        for (let colIndex = 0; colIndex < columnsMap.length; colIndex++) {
          this._target.setCellData(rowIndex, colIndex, rowItem[columnsMap[colIndex]], false, true);
        }

        if (rowItem.id && rowItem.id !== BLANK_ID) {
          if (Array.isArray(view.itemsRevertDelete)) {
            view.itemsRevertDelete.push(rowItem);
          } else {
            view.itemsRevertDelete = Vue.observable([rowItem])
          }
        } else {
          view.itemsAdded.push(rowItem)
        }
      }
      // cancle update when data revert in view
      view.itemsEdited.length = 0;
      view.refresh();
    })
  }

  redo() {
    const { row, row2 } = this.selection
    const view = this._target.collectionView
    let columnsMap = this._target.columns.map((column) => column.binding)

    view.deferUpdate(() => {
      const rowsData = getRowsData(this._target, row, row2, true)

      for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
        view.itemsRemoved.push(rowsData[rowIndex])

        for (let colIndex = 0; colIndex < columnsMap.length; colIndex++) {
          const colBinding = columnsMap[colIndex];

          if (colBinding === 'id') {
            this._target.setCellData(rowIndex, colIndex, BLANK_ID, false, true)
          } else {
            this._target.setCellData(rowIndex, colIndex, null, false, true)
          }
        }
      }

      view.refresh();
    })
  }
}

export class GridPastingAction extends Actionable {
  /**
   * @param {any} newState
   */
  set newState(newState) {
    this._newState = newState
  }

  undo() {
    const { col, col2 } = this.selection
    let countColumns = this._target.columns.length

    if ((col2 - col + 1) >= countColumns - 2) { // logic pasted full columns
      this.undoPastedFullColumns()
    } else { // logic pasted some columns
      this.undoPastedSomeColumns()
    }
  }

  undoPastedFullColumns() {
    const { row, row2 } = this.selection
    const view = this._target.collectionView
    const source = view.sourceCollection

    view.deferUpdate(() => {
      let rowsData = getRowsData(this._target, row, row2, true)
      let rowsRemovedIndex = []

      for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
        const oldRowData = { ...this._oldState[rowIndex] }

        if (!oldRowData.id || oldRowData.id === BLANK_ID) {
          let rowData = rowsData[rowIndex]
          rowsRemovedIndex.push(rowIndex)
          view.itemsRemoved.push(rowData)

          let columnsMap = this._target.columns.map((column) => column.binding)
          for (let colIndex = 0; colIndex < columnsMap.length; colIndex++) {
            const colBinding = columnsMap[colIndex];

            if (colBinding === 'id') {
              this._target.setCellData(rowIndex, colIndex, BLANK_ID, false, true)
            } else {
              this._target.setCellData(rowIndex, colIndex, null, false, true)
            }
          }
        } else {
          delete oldRowData.id
          const currentRowData = {
            ...source[rowIndex],
            ...oldRowData,
            type: oldRowData.type ? 1 : 0,
            type_1: oldRowData.type_1 ? 1 : 0,
          };

          let columnsMap = this._target.columns.map((column) => column.binding)

          for (let colIndex = 0; colIndex < columnsMap.length; colIndex++) {
            const colBinding = columnsMap[colIndex];

            if (colBinding === 'id') {
              //
            } else {
              let colValue = getRealValue(currentRowData[colBinding]);
              if (colBinding === 'status') {
                colValue = currentRowData[colBinding]
              }
              this._target.setCellData(rowIndex, colIndex, colValue, false, true)
            }
          }
        }
      }

      view.refresh();
    })
  }

  undoPastedSomeColumns() {
    let { row, row2, col, col2 } = this.selection
    if (col === 0) {
      col = col + 1
      col2 = col2 + 1
    }
    const view = this._target.collectionView
    const source = view.sourceCollection

    view.deferUpdate(() => {
      let columnsMap = this._target.columns.map((column) => column.binding)
      let newStateData = []
      for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
        const oldRowData = { ...this._oldState[rowIndex] }
        delete oldRowData.id
        const currentRowData = {
          ...source[rowIndex],
          ...oldRowData,
          type: oldRowData.type ? 1 : 0,
          type_1: oldRowData.type_1 ? 1 : 0,
        };

        for (let colIndex = col; colIndex <= col2; colIndex++) {
          const colBinding = columnsMap[colIndex];

          if (colBinding === 'id') {
            //
          } else {
            let colValue = getRealValue(currentRowData[colBinding]);
            if (colBinding === 'status') {
              colValue = currentRowData[colBinding] === null ? false : currentRowData[colBinding]
            }
            if (typeof newStateData[rowIndex] === 'undefined') {
              newStateData[rowIndex] = {};
            }
            newStateData[rowIndex][colBinding] = this._newState[rowIndex][colBinding]
            this._target.setCellData(rowIndex, colIndex, colValue, false, true)
          }
        }
      }

      for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
        this._newState[rowIndex] = {
          ...this._newState[rowIndex],
          ...newStateData[rowIndex]
        }
      }
    })
  }

  redo() {
    const { col, col2 } = this.selection
    let countColumns = this._target.columns.length

    if ((col2 - col + 1) >= countColumns - 2) { // logic pasted full columns
      this.redoPastedFullColumns()
    } else { // logic pasted some columns
      this.redoPastedSomeColumns()
    }
  }

  redoPastedFullColumns() {
    const { row, row2, col, col2 } = this.selection
    const view = this._target.collectionView
    const source = view.sourceCollection
    const columns = this._target.columns

    view.deferUpdate(() => {
      for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
        const oldRowData = { ...this._oldState[rowIndex] }
        const newRowData = { ...this._newState[rowIndex] }

        if (!oldRowData.id || oldRowData.id === BLANK_ID) {
          let columnsMap = this._target.columns.map((column) => column.binding)
          for (let colIndex = 0; colIndex < columnsMap.length; colIndex++) {
            const colBinding = columnsMap[colIndex];
            if (colBinding === 'id') {
              let colValue = getRealValue(newRowData[colBinding]);
              if (colBinding === 'status') {
                colValue = newRowData[colBinding]
              }
              this._target.setCellData(rowIndex, colIndex, colValue, false, false)
            } else {
              let colValue = getRealValue(newRowData[colBinding]);
              if (colBinding === 'status') {
                colValue = newRowData[colBinding]
              }
              this._target.setCellData(rowIndex, colIndex, colValue, false, false)
            }
          }

          let editedIndex = this._target.collectionView.itemsEdited.findIndex((itemEdited) => itemEdited.id === newRowData.id)

          if (editedIndex >= 0) {
            this._target.collectionView.itemsEdited.splice(editedIndex, 1)
          }
          let revertData = {
            ...newRowData,
            type: newRowData.type ? 1 : 0,
            type_1: newRowData.type_1 ? 1 : 0,
            id: newRowData.id || BLANK_ID
          }
          if (revertData.type > 0) {
            delete revertData.db_customize_id
          } else {
            delete revertData.db_master_id
          }

          if (revertData?.type_1 > 0) {
            delete revertData.db_customize_id_1
          } else {
            delete revertData.db_master_id_1
          }
          delete revertData.clientRowId;

          if (Array.isArray(view.itemsRevertDelete)) {
            view.itemsRevertDelete.push(revertData);
          } else {
            view.itemsRevertDelete = Vue.observable([revertData])
          }
        } else {
          delete newRowData.id
          const currentRowData = { ...source[rowIndex], ...newRowData };

          let columnsMap = this._target.columns.map((column) => column.binding)

          for (let colIndex = 0; colIndex < columnsMap.length; colIndex++) {
            const colBinding = columnsMap[colIndex];

            if (colBinding === 'id') {
              //
            } else {
              let colValue = getRealValue(currentRowData[colBinding]);
              if (colBinding === 'status') {
                colValue = currentRowData[colBinding]
              }
              this._target.setCellData(rowIndex, colIndex, colValue, false, true)
            }
          }
        }
      }

      view.refresh();
    })
  }

  redoPastedSomeColumns() {
    let { row, row2, col, col2 } = this.selection
    if (col === 0) {
      col = col + 1
      col2 = col2 + 1
    }
    const view = this._target.collectionView
    const source = view.sourceCollection

    view.deferUpdate(() => {
      let columnsMap = this._target.columns.map((column) => column.binding)

      for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
        const newRowData = { ...this._newState[rowIndex] }
        const currentRowData = { ...source[rowIndex], ...newRowData };

        for (let colIndex = col; colIndex <= col2; colIndex++) {
          const colBinding = columnsMap[colIndex];

          if (colBinding === 'id') {
            //
          } else {
            let colValue = getRealValue(currentRowData[colBinding]);
            if (colBinding === 'status') {
              colValue = !currentRowData[colBinding]
            }
            this._target.setCellData(rowIndex, colIndex, colValue, false, true)
          }
        }
      }
    })
  }
}

  // apply undo/redo for checkbox item
export class GridEditAction extends Actionable {
  constructor(target, oldState, selection) {
    super(target, oldState, selection)
    const { row, row2, col, col2 } = selection
    this._newState = getRangeData(target, row, row2, col, col2);
  }

  undo() {
    let { row, row2, col, col2 } = this.selection
    if (col === 0) {
      col = col + 1
      col2 = col2 + 1
    }
    const view = this._target.collectionView
    const source = view.sourceCollection

    view.deferUpdate(() => {
      let columnsMap = this._target.columns.map((column) => column.binding)

      for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
        const oldRowData = { ...this._oldState[rowIndex] }
        delete oldRowData.id
        const currentRowData = {
          ...source[rowIndex],
          ...oldRowData
        };

        for (let colIndex = col; colIndex <= col2; colIndex++) {
          const colBinding = columnsMap[colIndex];

          if (colBinding === 'id') {
            //
          } else {
            let colValue = getRealValue(currentRowData[colBinding]);
            if (colBinding === 'status') {
              colValue = !currentRowData[colBinding]
            }
            this._target.setCellData(rowIndex, colIndex, colValue, false, true)
          }
        }
      }
    })
  }

  redo() {
    let { row, row2, col, col2 } = this.selection
    if (col === 0) {
      col = col + 1
      col2 = col2 + 1
    }
    const view = this._target.collectionView
    const source = view.sourceCollection

    view.deferUpdate(() => {
      let columnsMap = this._target.columns.map((column) => column.binding)

      for (let rowIndex = row; rowIndex <= row2; rowIndex++) {
        const newRowData = { ...this._newState[rowIndex] }
        const currentRowData = { ...source[rowIndex], ...newRowData };

        for (let colIndex = col; colIndex <= col2; colIndex++) {
          const colBinding = columnsMap[colIndex];

          if (colBinding === 'id') {
            //
          } else {
            let colValue = getRealValue(currentRowData[colBinding]);
            if (colBinding === 'status') {
              colValue = !currentRowData[colBinding]
            }
            this._target.setCellData(rowIndex, colIndex, colValue, false, true)
          }
        }
      }
    })
  }
}

export class UndoStack {
  _stack = Vue.observable([])
  _ptr = 0
  _target = null

  stateChanged = null

  get actionCount() {
    return this._stack.length
  }

  get canUndo() {
    return this._stack.length > 0 && this._ptr > 0;
  }

  get canRedo() {
    return this._stack.length > 0 && this._ptr < this._stack.length;
  }

  constructor(target, stateChanged) {
    this._target = target
    this.stateChanged = stateChanged

    this.onWijmoEvents()
  }

  onWijmoEvents() {
    const flexgrid = this._target;
    let selection = {
      row: -1,
      row2: -1,
      col: -1,
      col2: -1,
    };
    let selectionData = {};
    let selectionRows = {};
    let oldStatePasted = null

    flexgrid.cellEditEnded.addHandler(
      debounce((s, e) => {
        if (e.custom) {
          return
        }
        // if cell dont have data
        if (s.getCellData(selection.row, selection.col, false) === null || s.getCellData(selection.row, selection.col, false) === undefined) {
          return
        }
        const action = new GridCellEditAction(s, selectionData, selection);
        this.pushAction(action);
      }, 100),
    );

    flexgrid.pasting.addHandler((s, e) => {
      const { row, row2 } = e.range;
      oldStatePasted = getRowsData(s, row, row2, true);
    });

    flexgrid.pasted.addHandler((s, e) => {
      const { row, row2, col, col2 } = e.range;
      const selection = { row, row2, col, col2 };
      const pastedRows = getRowsData(s, row, row2);

      let i = setInterval(() => {
        let pastedRowsTemp = getRowsData(s, row, row2, true);

        let allItemHasId = Object.values(pastedRowsTemp).every((item) => Number.isInteger(item.id) || (!item.emissions && (!item.id || item.id === BLANK_ID)))
        if (allItemHasId) {
          action.newState = pastedRowsTemp
          if (store.state.commonApp.isSucess) {
            clearInterval(i)
          }
        }
      }, 100);

      const action = new GridPastingAction(s, oldStatePasted, selection);

      action.newState = pastedRows
      this.pushAction(action);
    })

    let itemsRemoving = []

    flexgrid.deletingRow.addHandler((s, e) => {
      e.cancel = true
      let rowData = s.rows[e.row]

      if (rowData.dataItem.id && rowData.dataItem.id !== BLANK_ID) {
        let dataItem = getRowsData(s, e.row, e.row, true)[e.row]
        itemsRemoving.push(dataItem)
      }

      s.deferUpdate(() => {
        for (let colIndex = 0; colIndex < s.columns.length; colIndex++) {
          if (s.columns[colIndex]?.binding === 'id') {
            s.setCellData(e.row, colIndex, BLANK_ID, false, true);
          } else {
            s.setCellData(e.row, colIndex, null, false, true);
          }
        }
      });
    });

    flexgrid.deletingRow.addHandler(
      debounce((s, e) => {
        const action = new GridDeletingRowAction(s, selectionRows, selection);
        this.pushAction(action);

        for (const item of itemsRemoving) {
          s.collectionView.itemsRemoved.push(item)
        }

        itemsRemoving.length = 0
        let event = new NotifyCollectionChangedEventArgs(NotifyCollectionChangedAction.Remove)
        s.collectionView.onCollectionChanged(event)
      }, 100),
    );

    flexgrid.selectionChanging.addHandler(
      debounce((s, e) => {
        let row = e.range.row;
        let row2 = e.range.row2;
        let col = e.range.col;
        let col2 = e.range.col2;

        if (row2 < row) {
          row = e.range.row2;
          row2 = e.range.row;
        }

        if (col2 < col) {
          col = e.range.col2;
          col2 = e.range.col;
        }

        selection = {
          row,
          row2,
          col,
          col2,
        };

        selectionData = getRangeData(s, row, row2, col, col2);
        selectionRows = getRowsData(s, row, row2, true);
      }, 200),
    );

    flexgrid.hostElement.addEventListener('keydown', (e) => {
      if (e.keyCode === KEYS_CODE.DELETE) {
        const action = new GridCellEditAction(flexgrid, selectionData, selection);
        this.pushAction(action);

        let event = new NotifyCollectionChangedEventArgs(NotifyCollectionChangedAction.Change)
        flexgrid.collectionView.onCollectionChanged(event)
        // flexgrid.collectionView.itemsEdited.length = 0;
      }
    })
  }

  onStateChanged() {
    this.stateChanged(this)
  }

  pushAction(action) {
    this._pendingAction = action
    this.pushPendingAction()

    this.onStateChanged()
  }

  pushPendingAction() {
    if (!this._undoing && this._pendingAction) {
      this._stack.splice(this._ptr, this._stack.length - this._ptr);

      this._stack.push(this._pendingAction);
      this._ptr++;
      this._pendingAction = null;
    }
  }

  clear() {
    this._ptr = 0;
    this._stack.splice(0, this._stack.length);

    this.onStateChanged()
  }

  undo() {
    if (this.canUndo) {
      const t = this._stack[this._ptr - 1]
      this._ptr--;
      this._undoing = !0;
      t.undo()
      this._undoing = !1;
      this._pendingAction = null;

      this.onStateChanged()
      return t
    }
  }

  redo() {
    if (this.canRedo) {
      const t = this._stack[this._ptr]

      this._ptr++;
      this._undoing = !0;
      t.redo();
      this._undoing = !1;
      this._pendingAction = null;

      this.onStateChanged()
      return t
    }
  }
}
